import { Autoplay, EffectFade, Navigation, Pagination, Swiper, Thumbs } from 'swiper';

const Slider = {
  swiperInstances: [],
  selectors: {
    slider: '.md-slider',
    heightSetter: '#swiper-wrapper .swiper-slide .card',
  },
  init: function () {
    const $swiper = $(this.selectors.slider);
    let swiperInstances = this.swiperInstances;

    if ($swiper.length > 0) {
      $swiper.each(function () {
        let el = $(this);
        let component = el.find('.swiper');
        let thumbs = el.find('.swiper-thumbs');
        let thumbsSlider = '';

        let buttonPrev = el.find('.swiper-button-prev')[0];
        let buttonNext = el.find('.swiper-button-next')[0];

        let pagination = el.find('.swiper-pagination')[0];

        let thumbsConfig = '';

        if (thumbs.length > 0) {
          thumbsConfig = buildConfig(thumbs[0].dataset);

          thumbsConfig = Object.assign(thumbsConfig, {
            freeMode: true,
            watchSlidesProgress: true,
            grabCursor: true,
          });

          thumbsSlider = new Swiper(thumbs[0], thumbsConfig);
        }

        const config = buildSlider(component[0].dataset, buttonPrev, buttonNext, pagination, thumbsSlider);

        swiperInstances.push(new Swiper(component[0], config));
      });
    }
  },
  update: function () {
    const swipersInstances = this.swiperInstances;

    if (swipersInstances.length > 0) {
      swipersInstances.forEach((slider) => {
        slider.el.swiper.update();
      });
    }
  },
};

const buildSlider = function (dataset, buttonPrev, buttonNext, pagination, thumbsSlider) {
  let object = {};
  let modules = [];

  if (dataset.navigation === 'true') {
    modules.push(Navigation);

    object.navigation = {
      nextEl: buttonNext,
      prevEl: buttonPrev,
    };
  }

  if (dataset.pagination === 'true') {
    modules.push(Pagination);

    object.pagination = {
      el: pagination,
      clickable: pagination.dataset.clickable === 'true',
      dynamicBullets: pagination.dataset.dynamic === 'true',
    };
  }

  if (dataset.loop === 'true') {
    object.loop = true;
  }

  if (dataset.autoplay === 'true') {
    modules.push(Autoplay);

    object.autoplay = {
      delay: parseInt(dataset.autoplaySpeed),
      disableOnInteraction: true,
    };
  }

  if (dataset.thumbs === 'true') {
    modules.push(Thumbs);

    object.thumbs = {
      swiper: thumbsSlider,
    };
  }

  if (dataset.pointer === 'true') {
    object.grabCursor = true;
  }

  if (dataset.autoheight === 'true') {
    object.autoHeight = true;
  }

  if (!!dataset.speed) {
    object.speed = parseInt(dataset.speed);
  } else {
    object.speed = 500;
  }

  if (dataset.transition === 'fade') {
    modules.push(EffectFade);

    object.effect = 'fade';

    object.fadeEffect = {
      crossFade: true,
    };
  }

  if (dataset.viewXs || dataset.viewSm || dataset.viewLg || dataset.viewXl) {
    object.breakpoints = {
      0: {
        slidesPerView: parseFloat(dataset.viewXs) ?? 1,
        spaceBetween: parseFloat(dataset.spaceXs) ?? 1,
      },
      575: {
        slidesPerView: parseFloat(dataset.viewSm) ?? 1,
        spaceBetween: parseFloat(dataset.spaceSm) ?? 1,
      },
      991: {
        slidesPerView: parseFloat(dataset.viewLg) ?? 1,
        spaceBetween: parseFloat(dataset.spaceLg) ?? 1,
      },
      1200: {
        slidesPerView: parseFloat(dataset.viewXl) ?? 1,
        spaceBetween: parseFloat(dataset.spaceXl) ?? 1,
      },
    };
  }

  if (modules.length > 0) {
    object.modules = modules;
  }

  return object;
};

export { Slider };
export default Slider;