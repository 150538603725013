const Header = {
  init: function () {
    const $html = $('html');
    const $body = $('body');
    const $header = $('#top');
    const $headerToggler = $('[data-bs-toggle="collapse"].hamburger');
    const $headerHeight = $header.innerHeight();
    const $isOverlaping = $header.hasClass('header-overlap');
    const $headerSticky = ['sticky', 'sticky-downsize'].includes($header.attr('data-sticky'));
    const $headerTopNav = $('#top-nav');
    const $pageHeader = $('#main .md-container').children(':first');

    console.log($headerSticky);

    if ($headerToggler.length > 0) {
      $headerToggler.on('click', function () {
        let $this = $(this);

        if (!$this.hasClass('is-active')) {
          $this.addClass('is-active');

          if ($(window).width() < 992) {
            $headerTopNav.toggleClass('close');
            $body.addClass('menu-open');
            $html.addClass('menu-open');
          }
        } else {
          $this.removeClass('is-active');
          $body.removeClass('menu-open');
          $html.removeClass('menu-open');
          $headerTopNav.toggleClass('close');
        }
      });
    }

    //Sticky on mouse scroll
    if ($header.length > 0 && $headerSticky) {
      let position = $body.offset().top + $headerHeight;

      if (window.scrollY >= position) {
        $header.addClass('sticky');
        $html.addClass('sticky');

        $body.css('margin-top', $headerHeight);
      }

      $(window).scroll(function () {
        if ($(document).scrollTop() > position) {
          $header.addClass('sticky');
          $html.addClass('sticky');

          if ($headerSticky && $isOverlaping) {
            $pageHeader.css('margin-top', 0);
          } else {
            $body.css('margin-top', $headerHeight);
          }
        } else {
          $header.removeClass('sticky');
          $html.removeClass('sticky');

          if ($headerSticky && $isOverlaping) {
            $pageHeader.css('margin-top', -$headerHeight);
          } else {
            $body.css('margin-top', 0);
          }
        }
      });
    }

    $('.navbar-nav .menu-item.menu-item-has-children.dropdown .dropdown-menu .menu-item-has-children.dropdown > .dropdown-toggle').on('click', function (e) {
      e.stopPropagation();
    });
  },
};

export { Header };
export default Header;